import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import Image from '../../components/Image';

const NewBrandPage = () => (
    <Layout page="brand-page">
      <Seo title="BRAND"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="flex items-end md:ml-16">
          <Image filename="logo/morso.png" width={160} />
          <p className="text-3xl ml-4">/ モルソー</p>
        </h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block content-morso md:-mt-12">
            <div className="md:w-1/2 md:relative md:pb-20">
              <Image className="full-image" filename="brand/top-morso.jpg" />
              <ul className="w-1/2 mt-12">
                <li>
                  <Link to="/brand/morso/" className="text-link white py-0">
                    morso ブランドTOP
                  </Link>
                </li>
                <li className="mt-6">
                  <Link to="/brand/morso/about/" className="text-link white py-0">
                    morso について
                  </Link>
                </li>
                <li className="mt-6">
                  <Link to="/brand/morso/lineup/" className="text-link white py-0">
                    morso 商品一覧
                  </Link>
                </li>
              </ul>
              <div className="text-block md:w-8/12">
                <p>アンデルセンストーブのフラグシップブランドとして長年日本で販売されているデンマークのブランド。</p>
                <p className="mt-4">デンマーク王室御用達の称号を受けており創業160年以上の伝統と最新鋭の技術を兼ね備えたストーブを多数ラインナップしています。</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-head mt-12">
        <h2 className="flex items-end md:ml-16">
          <Image filename="logo/leda.png" width={180} />
          <p className="text-3xl ml-4">/ レダ</p>
        </h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block content-leda">
            <div className="md:w-1/2 md:ml-auto md:mr-0">
              <Image className="full-image" filename="brand/top-leda.jpg" />
              <ul className="w-1/2 mt-12">
                <li>
                  <Link to="/brand/leda/" className="text-link white py-0">
                    LEDA ブランドTOP
                  </Link>
                </li>
                <li className="mt-6">
                  <Link to="/brand/leda/about/" className="text-link white py-0">
                    LEDA について
                  </Link>
                </li>
                <li className="mt-6">
                  <Link to="/brand/leda/lineup/" className="text-link white py-0">
                    LEDA 商品一覧
                  </Link>
                </li>
              </ul>
              <div className="text-block md:w-full md:-ml-72">
                <p>2021年よりアンデルセンストーブに加わったドイツのブランド。工業大国で認められた品質と環境先進国の厳しい排出ガス基準をクリアしたモデルをラインナップしています。</p>
                <p className="mt-4">鋳物製造から組み立てまで自社で一貫しておこなっておりドイツ国内の鋳物ストーブシェアNo.1の実績です。</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-content full-layout mt-20">
        <div className="main-content">
          <div className="content-block">
            <div className="md:flex other-link">
              <div className="md:w-1/2 h-1/2">
                <Link className="md:hover:opacity-70 transition-opacity" to="/chimney/">
                  <Image className="w-full" filename="brand/top-chimney.jpg" />
                  <p className="futura text-white text-2xl">CHIMNEY<span className="c-yellow text-base">煙突</span></p>
                </Link>
              </div>
              <div className="md:w-1/2">
                <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/">
                  <Image className="w-full" filename="brand/top-accessory.jpg" />
                  <p className="futura text-white text-2xl">ACCESSORY<span className="c-yellow text-base">アクセサリ</span></p>
                </Link>
              </div>
            </div>
            <Link className="btn pdf full mt-20" href="/catalog/">
              PDFカタログ 一覧
            </Link>
          </div>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>ブランド</li>
        </ul>
      </section>
    </Layout>
);

export default NewBrandPage;
